import { DEFAULT_ZONE_KEY, playlistState } from '@/views/PlaylistPage/composables/playlistState'
import { error, success } from '@/utils'
import { computed, toRaw } from 'vue'
import { useStore } from 'vuex'
import { ZONING_LAYOUTS } from '@/constants'
import { usePlaylistActions } from '@/views/PlaylistPage/composables/usePlaylistActions'

const getZoningLayoutSettingsByType = type => ZONING_LAYOUTS.find(layout => layout.type === type)

const getSlideDtoWithoutWrapper = slide => slide.getDtoWithoutWrapper()

export function useSlideActions() {
  const { updatePlaylist, resetSelection, resetMultipleSelection, openModal } = usePlaylistActions()

  const store = useStore()

  const playlistId = computed(() => store.getters['playlist/playlistId'])
  const zoningLayoutType = computed(() => store.getters['playlist/zoningLayoutType'])
  const zoningLayoutSettings = computed(() => {
    return zoningLayoutType.value ? getZoningLayoutSettingsByType(zoningLayoutType.value) : null
  })

  const getActiveZoneKey = () => playlistState.selectedZoneKey || DEFAULT_ZONE_KEY

  const selectSlide = (id) => {
    playlistState.selectedSlideIds.has(id) ?playlistState.selectedSlideIds.delete(id) : playlistState.selectedSlideIds.add(id)
    if (!playlistState.selectedSlideIds?.size) {
      playlistState.selectedZoneKey = null
    }
  }

  const updateSlide = async({
    payload,
    zoneKey,
    changeLogs
  }) => {
    return store.dispatch('playlist/updatePlaylistSlide', {
      payload,
      zoneKey,
      changeLogs
    }).then(() => {
      resetSelection(true)
      success()
    }).catch(e => {
      error(e.message)
    })
  }

  const createSlide = async({
    payload,
    zoneKey,
    cb
  }) => {
    return store.dispatch('playlist/createPlaylistSlide', {
      payload,
      zoneKey
    }).then(() => {
      playlistState.openedLayoutZones = playlistState.openedLayoutZones.length
        ? playlistState.openedLayoutZones
        : [DEFAULT_ZONE_KEY]
      resetSelection()
      cb && cb()
      success()
    }).catch(e => {
      error(e.message)
    })
  }

  const openSlideModal = ({ type, slide = null, zoneKey = DEFAULT_ZONE_KEY }) => {
    playlistState.activeSlideType = type
    playlistState.activeSlideZoneKey = zoneKey
    playlistState.activeSlide = slide

    const modalMap = {
      CustomSlide: 'customTemplate',
      SmartTemplateSlide: 'smartTemplate',
      AISlide: 'aiContentSlide'
    }

    openModal(modalMap[type] || 'slide')
  }

  const startSlideAdd = ({ slideType, zoneKey }) => {
    openSlideModal({ type: slideType, zoneKey })
  }

  const startSlideEdit = ({ slide, zoneKey }) => {
    openSlideModal({ type: slide.constructor.type, slide, zoneKey })
  }
  const startSlideInfoEdit = ({ slide, zoneKey }) => {
    playlistState.activeSlide = slide
    openModal('slideInfo')
    playlistState.activeSlideZoneKey = zoneKey
  }

  const startSlideObjectFitEdit = ({ slide, zoneKey }) => {
    playlistState.activeSlide = slide
    openModal('editObjectFit')
    playlistState.activeSlideZoneKey = zoneKey
  }

  const startSlideExport = ({
    slide,
    zoneKey
  } = {}) => {
    playlistState.selectedZoneKey = getActiveZoneKey()
    openModal('slideExport')
    playlistState.activeSlide = slide
  }

  const deleteSlide = ({
    slide,
    zoneKey
  }) => {
    const payload = {
      slideId: slide.id
    }

    const changeLogs = [{
      slideDeleted: {
        slideId: slide.id,
        slideName: slide.getName()
      }
    }]

    playlistState.selectedSlideIds.delete(slide.id)

    return store.dispatch('playlist/deletePlaylistSlide', {
      payload,
      zoneKey,
      changeLogs
    }).then(() => {
      resetSelection()
      success()
    }).catch(e => {
      error(e.message)
    })
  }

  const deleteSelectedArrayOfSlides = () => {
    const zones = []
    const zoneKey = getActiveZoneKey()

    const zoneKeys = [...Object.keys(playlistState.zoneSlideLists)].slice(0, zoningLayoutSettings.value.zones)

    let slidesToDeleteArray

    zoneKeys.forEach((zone) => {
      const isCurrentZone = zone === zoneKey
      const slides = isCurrentZone
        ? playlistState.zoneSlideLists[zone].filter(slide => !playlistState.selectedSlideIds.has(slide.id))
        : playlistState.zoneSlideLists[zone]

      zones.push({ slides: slides.map(getSlideDtoWithoutWrapper) })
      if (isCurrentZone) {
        slidesToDeleteArray = playlistState.zoneSlideLists[zone].filter(slide => playlistState.selectedSlideIds.has(slide.id))
      }
    })

    const input = {
      layout: {
        type: zoningLayoutType.value,
        zones
      }
    }

    const changeLogs = slidesToDeleteArray.map(slide => {
      return {
        slideDeleted: {
          slideId: slide.id,
          slideName: slide.getName()
        }
      }
    })

    updatePlaylist({
      input,
      changeLogs
    })
  }

  const duplicateSlide = ({
    slide,
    zoneKey
  }) => {
    slide?.id && duplicateSelectedOrPassedArrayOfSlides({
      slideIds: new Set([slide.id]),
      zoneKey
    })
  }

  const duplicateSelectedOrPassedArrayOfSlides = ({
    slideIds,
    zoneKey
  } = {}) => {
    const slideIdsToDuplicate = slideIds || playlistState.selectedSlideIds
    zoneKey = zoneKey || getActiveZoneKey()
    const zones = []
    const zoneKeys = [...Object.keys(playlistState.zoneSlideLists)].slice(0, zoningLayoutSettings.value.zones)
    let slidesToDuplicateArray

    zoneKeys.forEach((zone) => {
      const isCurrentZone = zone === zoneKey
      let slides = playlistState.zoneSlideLists[zone]
      if (isCurrentZone) {
        slidesToDuplicateArray = slides.filter(slide => slideIdsToDuplicate.has(slide.id))
        slides = [...slides, ...slidesToDuplicateArray]
      }

      zones.push({ slides: slides.map(getSlideDtoWithoutWrapper) })
    })

    const input = {
      layout: {
        type: zoningLayoutType.value,
        zones: zones
      }
    }

    const changeLogs = slidesToDuplicateArray.map(slide => {
      return {
        slideDuplicated: {
          slideId: slide.id,
          slideName: slide.getName()
        }
      }
    })

    updatePlaylist({
      input,
      changeLogs
    })
  }

  const exportArrayOfSlides = (references) => {
    const keys = playlistState.activeSlide ? new Set([playlistState.activeSlide.id]) : toRaw(playlistState.selectedSlideIds)
    const slideIds = getSelectedWidgets(keys).map(slide => slide.id)
    const fromPlaylistId = playlistId.value
    const zoneKey = getActiveZoneKey()
    const fromZoneId = store.getters['playlist/layoutZoneIdByKey'](zoneKey)
    const payload = {
      slideIds,
      fromPlaylistId,
      fromZoneId,
      toReferences: references
    }
    resetSelection()
    store.dispatch('playlist/copyPlaylistSlides', payload).then(() => {
      resetMultipleSelection()
      success()
    }).catch(e => {
      error(e.message)
    })
  }

  const changeSlideDuration = ({ slide, duration, widgetId, toAll, zoneKey }) => {
    const { id, paused } = slide

    if (toAll) {
      const zones = Object.keys(playlistState.zoneSlideLists).map(zone => ({
        slides: playlistState.zoneSlideLists[zone].map(s => {
          s.waitingForUpdate = true
          return {
          ...s.getDtoWithoutWrapper(),
          ...(zone === zoneKey ? { duration } : null)
        }})
      }))

      const input = {
        layout: {
          type: zoningLayoutType.value,
          zones: zones.slice(0, zoningLayoutSettings.value.zones)
        }
      }

      const changeLogs = playlistState.zoneSlideLists[zoneKey].map(s => ({
        slideChangedDuration: { slideId: s.id }
      }))

      updatePlaylist({ input, changeLogs })
    } else {
      slide.waitingForUpdate = true
      updateSlide({
        payload: {
          slideId: id,
          input: { duration, paused, widgetId }
        },
        zoneKey,
        changeLogs: [{ slideChangedDuration: { slideId: id, slideName: slide.getName() } }]
      })
    }
  }

  const pauseSlide = ({ slide, paused, widgetId, zoneKey }) => {
    slide.waitingForUpdate = true
    updateSlide({
      payload: {
        slideId: slide.id,
        input: { paused, widgetId }
      },
      zoneKey,
      changeLogs: [{ slideUpdated: { slideId: slide.id, slideName: slide.getName() } }]
    })
  }

  const toggleSlideSound = ({ slide, mute, zoneKey }) => {
    slide.setMute(mute)
    slide.waitingForUpdate = true

    const changeLogs = [{
      ...(mute ? { slideMuted: { slideId: slide.id } } : { slideUnmuted: { slideId: slide.id, slideName: slide.getName() } })
    }]

    updateSlide({
      payload: {
        slideId: slide.id,
        input: slide.getDto({ ignoreWidgetId: true })
      },
      zoneKey,
      changeLogs
    })
  }

  const changeSlideObjectFit = ({ slide, objectFit, zoneKey, toAll }) => {
    zoneKey = zoneKey || playlistState.activeSlideZoneKey

    if (toAll) {
      const slideType = slide.type

      const zones = Object.keys(playlistState.zoneSlideLists).map(zone => ({
        slides: playlistState.zoneSlideLists[zone].map(s => {
          if (s.type === slideType) {
            s.setObjectFit(objectFit)
            s.waitingForUpdate = true
          }
          return s.getDto({ ignoreWidgetId: true })
        })
      }))

      const input = {
        layout: {
          type: zoningLayoutType.value,
          zones: zones.slice(0, zoningLayoutSettings.value.zones)
        }
      }

      const changeLogs = playlistState.zoneSlideLists[zoneKey]
        .filter(s => s.type === slideType)
        .map(s => ({ slideUpdated: { slideId: s.id, slideName: s.getName() } }))

      updatePlaylist({ input, changeLogs })
    } else {
      slide.setObjectFit(objectFit)
      slide.waitingForUpdate = true

      updateSlide({
        payload: {
          slideId: slide.id,
          input: slide.getDto({ ignoreWidgetId: true })
        },
        zoneKey,
        changeLogs: [{ slideUpdated: { slideId: slide.id, slideName: slide.getName() } }]
      })
    }
  }

  const getSelectedWidgets = (keys) => {
    return playlistState.zoneSlideLists[playlistState.selectedZoneKey]?.filter(s => keys.has(s.id))
  }

  return {
    changeSlideDuration,
    pauseSlide,
    toggleSlideSound,
    changeSlideObjectFit,
    selectSlide,
    updateSlide,
    createSlide,
    deleteSlide,
    deleteSelectedArrayOfSlides,
    startSlideAdd,
    startSlideEdit,
    startSlideExport,
    startSlideInfoEdit,
    startSlideObjectFitEdit,
    exportArrayOfSlides,
    duplicateSlide,
    duplicateSelectedOrPassedArrayOfSlides,
  }
}
