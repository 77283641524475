<script setup>
import {
  computed,
  createVNode,
  inject, onBeforeUnmount,
  onMounted,
  reactive,
  ref,
  toRefs,
  watch,
  watchEffect
} from 'vue'
import { cloneDeep, debounce } from 'lodash-es'
import {
  ExclamationCircleOutlined,
  NodeIndexOutlined
} from '@ant-design/icons-vue'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { error, success } from '@/utils'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { Modal } from 'ant-design-vue'
import { useI18n } from 'vue-i18n'
import SlideModal from '@/components/slideModal/SlideModal.vue'
import { DEFAULT_TRANSITION, LAYOUT_ZONES_SETTINGS, ZONING_LAYOUTS } from '@/constants'
import { createSlides, DISABLED_WIDGETS_TYPES, WIDGET_TYPE_LIST_MENU_ITEMS } from '@/helpers/Slides'
import ZoneCollapsePanel from '@/components/ZoneCollapsePanel.vue'
import ChangeLogsList from '@/components/ChangeLogsList.vue'
import TemplateConstructorModal from '@/components/slideModal/TemplateConstructorModal.vue'
import AIContentSlideModal from '@/components/slideModal/AIContentSlideModal.vue'
import FixedFooter from '@/components/FixedFooter.vue'
import ExportSlideModal from '@/components/inputModals/ExportSlideModal.vue'
import MoveToOtherGroupModal from '@/components/inputModals/MoveToOtherGroupModal.vue'
import CustomTemplateModal from '@/components/slideModal/CustomTemplateModal.vue'
import CEngineEditor from '@/components/cEngineEditor/CEngineEditor.vue'
import EditModal from './components/EditModal.vue'
import DesignTab from './components/DesignTab.vue'
import WidgetsTab from './components/WidgetsTab.vue'
import ExtraActions from './components/ExtraActions.vue'
import SelectionFooter from './components/SelectionFooter.vue'
import SoundtrackButton from './components/SoundtrackButton.vue'
import PlayPauseButton from './components/PlayPauseButton.vue'
import ExpirationModal from './components/ExpirationModal.vue'
import SmartPlaylistInfoModal from '@/components/inputModals/SmartPlaylistInfoModal.vue'
import StickyFooter from '@/components/StickyFooter.vue'
import SlideInfoEditModal from '@/components/inputModals/SlideInfoEditModal.vue'
import SlideObjectFitEditModal from '@/components/inputModals/SlideObjectFitEditModal.vue'
import { usePlaylistActions } from '@/views/PlaylistPage/composables/usePlaylistActions'
import { DEFAULT_ZONE_KEY, playlistState } from '@/views/PlaylistPage/composables/playlistState'
import { useSlideActions } from '@/views/PlaylistPage/composables/useSlideActions'
import ScheduleLink from '@/views/PlaylistPage/components/ScheduleLink.vue'

dayjs.extend(utc)

const FILTER = 'filter'
const HIGHLIGHT = 'highlight'
const DISABLED_WIDGETS_SLIDE_NAMES = DISABLED_WIDGETS_TYPES

const getSlideDtoWithoutWrapper = slide => slide.getDtoWithoutWrapper()

const getZoningLayoutSettingsByType = type => ZONING_LAYOUTS.find(layout => layout.type === type)

const props = defineProps({
  playlistId: {
    type: String,
    required: true
  },
})

const emit = defineEmits(['delete-playlist'])

const {
  selectSlide,
  updateSlide,
  createSlide,
  deleteSlide,
  deleteSelectedArrayOfSlides,
  duplicateSlide,
  duplicateSelectedOrPassedArrayOfSlides,
  exportArrayOfSlides,
  startSlideAdd,
  startSlideEdit,
  startSlideInfoEdit,
  startSlideObjectFitEdit,
  startSlideExport,
  changeSlideDuration,
  pauseSlide,
  toggleSlideSound,
  changeSlideObjectFit
} = useSlideActions()

const {
  startPlaylistEdit,
  startPlaylistDuplicate,
  startPlaylistMove,
  setPreferredPlaylist,
  updatePlaylist,
  changeZoning,
  resetSelection,
  resetMultipleSelection
} = usePlaylistActions()

const store = useStore()
const { t } = useI18n()
const router = useRouter()
const { playlistId } = toRefs(props)
const isDraggingNewSlide = ref(false)
const showChangeLog = ref(false)
const siderCollapsed = ref(JSON.parse(window.localStorage.getItem('playlistSiderCollapsed') || 'false'))
const filterSlideTypes = ref('')
const activeSlideZoneKey = ref(null)
const playlistWrapper = ref(null)
const duplicatePlaylistOnMove = ref(false)
const selectedZoneKey = ref(null)
const addSlideAfterIndex = ref(null)
const playlistSiderTab = ref('widgets')
const onboardingService = inject('onboardingService')
const playlist = computed(() => store.getters['playlist/playlist'])
const playlistVersionsEnabled = computed(() => store.getters['workspace/features/playlistVersionsEnabled'])
const playlistIsMain = computed(() => store.getters['playlist/playlistIsMain'])
const playlistTransitionType = computed(() => store.getters['playlist/playlistTransitionType'])
const transitionType = ref(playlistTransitionType.value || DEFAULT_TRANSITION)
const widgetDisplayRestrictions = computed(() => store.getters['playlist/widgetDisplayRestrictions'])
const playlistLoading = computed(() => store.getters['playlist/playlistLoading'])
const groupLoading = computed(() => store.getters['groups/currentGroupIsLoading'])
const currentGroupTypeIsSmart = computed(() => store.getters['groups/currentGroupTypeIsSmart'])
const currentGroupPlaylistsMovable = computed(() => store.getters['groups/currentGroupPlaylistsMovable'] && !playlistIsMain.value)
const zoningLayoutType = computed(() => store.getters['playlist/zoningLayoutType'])
const zoningLayoutShuffle = computed(() => store.getters['playlist/zoningLayoutShuffle'])
const mainZoneSlides = computed(() => store.getters['playlist/layoutMainZoneSlides'])
const zoneASlides = computed(() => store.getters['playlist/layoutZoneASlides'])
const zoneBSlides = computed(() => store.getters['playlist/layoutZoneBSlides'])
const currentGroupScheduleId = computed(() => store.getters['groups/currentGroupScheduleId'])
const currentGroupHasForcedPlaylist = computed(() => store.getters['groups/currentGroupHasForcedPlaylist'])
const disableForcedPlaylistOption = computed(() => currentGroupHasForcedPlaylist.value || playlist.value?.paused)
const forcedPlaylist = computed(() => store.getters['groups/currentGroupForcedPlaylist'])
const autoDeleteOptions = computed(() => playlist.value?.autoDeleteOptions)
const autoDeleteDate = computed(() => playlist.value?.autoDeleteOptions?.deleteAt ? dayjs(playlist.value.autoDeleteOptions.deleteAt).utc().format('MMM DD YYYY') : null)
const playlistWrapperHeight = ref(0)
const zoneRefs = reactive({
  MAIN: null,
  ZONE_A: null,
  ZONE_B: null
})
const zoningLayoutSettings = computed(() => {
  return zoningLayoutType.value ? getZoningLayoutSettingsByType(zoningLayoutType.value) : null
})
const slideTypeList = computed(() => {
  return WIDGET_TYPE_LIST_MENU_ITEMS.filter(w => {
    return w.id.toLowerCase().includes(filterSlideTypes.value.toLowerCase()) ||
     w.name.toLowerCase().includes(filterSlideTypes.value.toLowerCase())
  })
})
const loading = computed(() => playlistLoading.value || groupLoading.value)
const editOnlyMode = computed(() => playlistState.filterSlidesState.type === FILTER && !!playlistState.filterSlidesState.input)
const highlightMode = computed(() => playlistState.filterSlidesState.type === HIGHLIGHT && !!playlistState.filterSlidesState.input)
const layoutZonesSettings = computed(() => LAYOUT_ZONES_SETTINGS.slice(0, zoningLayoutSettings.value?.zones))
const showForcedPlaylistOption = computed(() => !currentGroupTypeIsSmart.value)
const playlistSiderTabOptions = computed(() => [
  { label: t('components.playlistPage.widgets'), value: 'widgets' },
  { label: t('components.playlistPage.design'), value: 'design' }
])
const isSmartTemplateActive = computed(()=> playlistState.activeSlideType === 'SmartTemplateSlide')

const zoneSlidesRaw = reactive({
  MAIN: mainZoneSlides,
  ZONE_A: zoneASlides,
  ZONE_B: zoneBSlides
})

const filterSlidesOptions = computed(() => [
  { label: t('components.playlistPage.filter'), value: FILTER },
  { label: t('components.playlistPage.highlight'), value: HIGHLIGHT }
])

let resizeObserver

const setupResizeObserver = () => {
  if (!resizeObserver && playlistWrapper.value) {
    resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        playlistWrapperHeight.value = entry.contentRect.height
      }
    })

    resizeObserver.observe(playlistWrapper.value.$el)
  }
}

onMounted(() => {
  setSlideLists()
  setSlideListsFiltered()
  setupResizeObserver()
})

onBeforeUnmount(() => {
  if (resizeObserver) {
    resizeObserver.disconnect()
  }
})

const mapRawSlidesToSlideList = (raw, currentSlideObjects) => {
  if (!currentSlideObjects || !currentSlideObjects.length) {
    return createSlides(cloneDeep(raw))
  }
  return cloneDeep(raw).map(slide => {
    const existing = currentSlideObjects.find((slideObj) => slideObj.id === slide.id)
    if (existing) {
      return existing.updateSlide(slide)
    } else {
      return (createSlides([slide]))?.[0]
    }
  })
}

const setSlideLists = () => {
  playlistState.zoneSlideLists.MAIN = mapRawSlidesToSlideList(zoneSlidesRaw.MAIN, playlistState.zoneSlideLists.MAIN)
  playlistState.zoneSlideLists.ZONE_A = mapRawSlidesToSlideList(zoneSlidesRaw.ZONE_A, playlistState.zoneSlideLists.ZONE_A)
  playlistState.zoneSlideLists.ZONE_B = mapRawSlidesToSlideList(zoneSlidesRaw.ZONE_B, playlistState.zoneSlideLists.ZONE_B)
}

const setSlideListsFiltered = () => {
  playlistState.zoneSlideListsFiltered.MAIN = getFilteredSlideList(playlistState.zoneSlideLists.MAIN)
  playlistState.zoneSlideListsFiltered.ZONE_A = getFilteredSlideList(playlistState.zoneSlideLists.ZONE_A)
  playlistState.zoneSlideListsFiltered.ZONE_B = getFilteredSlideList(playlistState.zoneSlideLists.ZONE_B)
}

const debounceFilter = debounce(setSlideListsFiltered, 300)

const getFilteredSlideList = (slideList) => {
  if (editOnlyMode.value) {
    return slideList.filter(slideIncludesFilters)
        .map(slide => {
          slide.setEditOnlyMode(true)
          slide.setHighlighted(false)
          return slide
        })
  } else if (highlightMode.value) {
    return slideList.map(slide => {
      slide.setHighlighted(slideIncludesFilters(slide))
      slide.setEditOnlyMode(false)
      return slide
    })
  } else {
    return slideList.map(slide => {
      slide.setHighlighted(false)
      slide.setEditOnlyMode(false)
      return slide
    })
  }
}

const slideIncludesFilters = (slide) => {
  const filter = playlistState.filterSlidesState.input?.toLowerCase()
  return slide.widgetType.toLowerCase().includes(filter) ||
      slide.getName()?.toLowerCase().includes(filter)
}

const onSlideClick = ({
  slide,
  zoneKey,
  e
}) => {
  if (e.shiftKey) {
    if (selectedZoneKey.value && selectedZoneKey.value !== zoneKey) {
      return
    }
    if (!playlistState.selectedSlideIds?.size) {
      selectedZoneKey.value = zoneKey
    }
    selectSlide(slide.id)
  } else {
    startSlideEdit({
      slide,
      zoneKey
    })
  }
}

const deselectAll = () => {
  resetSelection()
  resetMultipleSelection()
}


const openExpirationSettings = () => {
  playlistState.showModals.playlistExpiration = true
}

const onMultipleSlidesSave = async ({ widgetsModels, afterSlideId }) => {
  const zoneKey = playlistState.activeSlideZoneKey
  for (let i = 0; i < widgetsModels.length; i++) {
    const dto = widgetsModels[i]
    await updateOrCreateSlide(dto, {afterSlideId, zoneKey})
  }
  addSlideAfterIndex.value = null
}

const updateOrCreateSlide = async (widgetModel, {afterSlideId, zoneKey} = {}) => {
  const activeSlideValue = playlistState.activeSlide
  const activeSlideId = activeSlideValue?.id
  const input = widgetModel
  zoneKey = zoneKey || playlistState.activeSlideZoneKey
  const afterIndex = addSlideAfterIndex.value
  const isNewSlide = !input.widgetId
  delete input.widgetId
  const payload = {
    input
  }
  if (isNewSlide) {
    if (afterSlideId) {
      payload.afterSlideId = afterSlideId
    }
    else if (afterIndex) {
      const prevSlide = playlistState.zoneSlideLists[zoneKey][afterIndex - 1] || null
      if (prevSlide) {
        payload.afterSlideId = prevSlide.id || null
      }
    }
    if (!playlistState.openedLayoutZones?.includes(zoneKey)) {
      playlistState.openedLayoutZones?.push(zoneKey)
    }
    const isLast = afterIndex === playlistState.zoneSlideLists[zoneKey]?.length
    await createSlide({
      payload,
      zoneKey,
      cb: () => {
        if (isLast) {
          zoneRefs[zoneKey]?.scrollToBottom && zoneRefs[zoneKey]?.scrollToBottom()
        }
      }
    }).then(()=>{
      if (zoneKey === DEFAULT_ZONE_KEY) {
        if (input.createWidgetWrapper?.widgetWorldClock) {
          onboardingService.proceedFrom('worldClock')
        }
        else if (input.createWidgetWrapper?.widgetWeather) {
          onboardingService.proceedFrom('weatherForecast')
        }
      }
    })
  } else {
    payload.slideId = activeSlideId
    activeSlideValue && activeSlideValue.setWaitingForUpdate(true)
    const changeLogs = [{
      slideUpdated: {
        slideId: activeSlideId,
        slideName: playlistState.activeSlide?.getName()
      }
    }]
    await updateSlide({
      payload,
      zoneKey,
      changeLogs
    })
  }
}

const onSlideUpdate = (widgetModel) => {
  updateOrCreateSlide(widgetModel)
  resetSelection(true)
  addSlideAfterIndex.value = null
}

const onSlidesUpdateCancel = () => {
  if (activeSlideZoneKey.value === DEFAULT_ZONE_KEY) {
    if (playlistState.activeSlideType === 'ClockSlide') {
      onboardingService.proceedFrom('worldClock')
    }
    else if (playlistState.activeSlideType === 'WeatherSlide') {
      onboardingService.proceedFrom('weatherForecast')
    }
  }
  resetSelection()
}

const onSlideAdd = ({
  afterIndex,
  slideType,
  zoneKey
}) => {
  addSlideAfterIndex.value = afterIndex
  playlistState.zoneSlideLists[zoneKey].splice(afterIndex, 1)
  startSlideAdd({
    slideType,
    zoneKey
  })
}

const onSlideAddLast = (slideType, optZoneKey) => {
  const [zoneKey] = optZoneKey
      ? [optZoneKey]
      : playlistState.openedLayoutZones?.length === 1
          ? playlistState.openedLayoutZones
          : [DEFAULT_ZONE_KEY]
  addSlideAfterIndex.value = playlistState.zoneSlideLists[zoneKey]?.length || 0
  startSlideAdd({
    slideType,
    zoneKey
  })
}

const onSlideListChange = ({
  slideIndex,
  down,
  slide,
  zoneKey
}) => {
  const fromZoneKey = store.getters['slides/draggingSlideFromZone']
  const fromZoneId = fromZoneKey ? store.getters['playlist/layoutZoneIdByKey'](fromZoneKey) : null
  const toZoneId = store.getters['playlist/layoutZoneIdByKey'](zoneKey)
  const sameZone = fromZoneKey === zoneKey
  const prevSlideIndex = sameZone ?
      down ? slideIndex : slideIndex - 1
      : slideIndex - 1
  const prevSlide = playlistState.zoneSlideLists[zoneKey][prevSlideIndex] || null
  const payload = {
    slideId: slide.id,
    toZoneId
  }
  payload.afterSlideId = prevSlide?.id || null
  const changeLogs = [
    {
      slideChangedOrder: {
        slideId: slide.id,
        slideName: slide.getName()
      }
    }
  ]
  store.dispatch('playlist/movePlaylistSlide', {
    payload,
    zoneId: fromZoneId,
    changeLogs
  }).then(() => {
    success()
  })
}

const onSlideDurationChange = changeSlideDuration

const onSlidePausedChange = pauseSlide

const onSlideExportPlaylistsSelect = (references) => {
  exportArrayOfSlides(references)
}

const onPlaylistMoveSelect = (groupId) => {
  if (duplicatePlaylistOnMove.value) {
    return handlePlaylistCopy({ groupId }).then(() => {
      success()
    }).catch((e) => {
      error(e.message || 'Error')
    })
  } else {
    handlePlaylistMove({ groupId }).then(() => {
      router.replace({ params: { groupId } })
      success()
    }).catch((e) => {
      error(e.message || 'Error')
    })
  }
}

const handlePlaylistCopy = (payload) => {
  return store.dispatch('playlist/copyPlaylist', payload)
}

const handlePlaylistMove = (payload) => {
  return store.dispatch('playlist/movePlaylist', payload)
}

const onPlaylistMoveCancel = () => {
}

const toggleSider = () => {
  siderCollapsed.value = !siderCollapsed.value
  window.localStorage.setItem('playlistSiderCollapsed', JSON.stringify(siderCollapsed.value))
}

const onStartDragNewSlide = () => {
  isDraggingNewSlide.value = true
}

const onStopDragNewSlide = () => {
  isDraggingNewSlide.value = false
}

const onPlaylistUpdate = ({
  name,
  color
}) => {
  const changeLogs = [{
    unknownChanges: {}
  }]
  updatePlaylist({
    input: {
      name,
      color
    },
    changeLogs
  })
}

const onPlaylistExpirationSettingsChange = (input) => {
  const changeLogs = [{
    unknownChanges: {}
  }]
  updatePlaylist({
    input,
    changeLogs
  })
}

const onTransitionChange = (value) => {

  const zoneKeys = [...Object.keys(playlistState.zoneSlideLists)].slice(0, zoningLayoutSettings.value.zones)

  const zones = zoneKeys.map(zoneKey => {
    return { slides: playlistState.zoneSlideLists[zoneKey].map(getSlideDtoWithoutWrapper) }
  })

  const input = {
    layout: {
      transitionType: value,
      type: zoningLayoutType.value,
      zones: zones
    }
  }

  const changeLogs = [{
    transitionTypeChanged: {}
  }]

  updatePlaylist({
    input,
    changeLogs
  })
}

const handleOverrideGroupSchedule = () => {
  return Modal.confirm({
    title: t('components.playlistPage.overrideModalTitle'),
    icon: createVNode(ExclamationCircleOutlined),
    okText: t('components.playlistPage.overrideModalOkText'),
    cancelText: t('components.playlistPage.overrideModalCancelText'),
    content: t('components.playlistPage.overrideModalCancelContent'),
    onOk () {
      overrideGroupSchedule()
    }
  })
}

const overrideGroupSchedule = () => {
  const payload = {
    id: currentGroupScheduleId.value,
    input: {
      data: {
        forcedPlaylistId: playlistId.value
      }
    }
  }
  return store.dispatch('groups/updateCurrentGroupSchedule', payload).then(() => {
    success()
  }).catch(e => {
    error(e.message)
  })
}

const handleZoningChange = (layout) => {
  const currentZones = zoningLayoutSettings.value.zones
  const zones = layout.zones

  const shouldChangeZoning =
      currentZones <= zones ||
      (zones === 2 && zoneIsEmpty('ZONE_B')) ||
      (zones === 1 && zoneIsEmpty('ZONE_A') && zoneIsEmpty('ZONE_B'))

  if (shouldChangeZoning) {
    return changeZoning(layout)
  }

  return Modal.confirm({
    title: t('components.playlistPage.zoneModalTitle'),
    icon: createVNode(ExclamationCircleOutlined),
    okText: t('components.playlistPage.zoneModalOkText'),
    cancelText: t('components.playlistPage.zoneModalCancelText'),
    content: t('components.playlistPage.zoneModalCancelContent'),
    onOk () {
      changeZoning(layout)
    }
  })
}

const openChangeLog = () => {
  showChangeLog.value = true
}

const handleTogglePlaylistPause = async (value) => {
  if (forcedPlaylist.value && forcedPlaylist.value.id === playlist.value.id && value) {
    return Modal.confirm({
      title: t('components.playlistPage.forcedPlaylistWarningTitle'),
      content: t('components.playlistPage.forcedPlaylistWarningContent'),
      okText: t('components.playlistPage.forcedPlaylistWarningOkText'),
      cancelText: t('components.playlistPage.forcedPlaylistWarningCancelText'),
      onOk: async () => {
        await deactivateForcedPlaylist()
        togglePlaylistPause(value)
      }
    })
  }
  else {
    togglePlaylistPause(value)
  }
}

const deactivateForcedPlaylist = async () => {
  const payload = {
    groupId: forcedPlaylist.value?.groupId,
    input: {
      data: {
        forcedPlaylistId: null
      }
    }
  }
  return store.dispatch('groups/updateGroupScheduleByGroupId', payload)
}

const togglePlaylistPause = (value) => {
  const changeLogs = [{
    unknownChanges: {}
  }]
  updatePlaylist({
    input: { paused: value },
    changeLogs
  }).then(() => {
    playlist.value.paused = value
  })
}

const handleTogglePlaylistShuffle = () => {
  const zoneKeys = [...Object.keys(playlistState.zoneSlideLists)].slice(0, zoningLayoutSettings.value.zones)

  const zones = zoneKeys.map(zoneKey => {
    return { slides: playlistState.zoneSlideLists[zoneKey].map(getSlideDtoWithoutWrapper) }
  })

  const input = {
    layout: {
      type: zoningLayoutType.value,
      zones: zones,
      shuffle: !zoningLayoutShuffle.value
    }
  }

  const changeLogs = [{
    unknownChanges: {}
  }]

  updatePlaylist({
    input,
    changeLogs
  })
}

const zoneIsEmpty = (zoneKey) => playlistState.zoneSlideLists[zoneKey].length === 0

const setZoneRefs = (zoneKey, ref) => {
  zoneRefs[zoneKey] = ref
}

watch(() => props.playlistId, () => {
  setPreferredPlaylist(playlistId.value)
  resetMultipleSelection()
  playlistState.openedLayoutZones = [DEFAULT_ZONE_KEY]
  selectedZoneKey.value = null
  setSlideLists()
  setSlideListsFiltered()
})

watch(playlist, () => {
  setSlideLists()
  setSlideListsFiltered()
})

watch(playlistTransitionType, (value) => {
  transitionType.value = value || DEFAULT_TRANSITION
})

watch(() => playlistState.filterSlidesState.type, () => {
  setSlideListsFiltered()
})

watchEffect(() => {
  store.dispatch('playlist/getPlaylist', playlistId.value)
})

watch(playlistWrapper, (newVal) => {
  if (newVal) {
    setupResizeObserver()
  }
})
</script>

<template>
  <CEngineEditor
    v-if="false"
    :slide="playlistState.activeSlide"
  />
  <CustomTemplateModal
    v-model:visible="playlistState.showModals.customTemplate"
    :slide-obj="playlistState.activeSlide"
    :zone-key="playlistState.activeSlideZoneKey"
    :zoning-layout-type="zoningLayoutType"
    @update="onSlideUpdate"
    @close="onSlidesUpdateCancel"
  />
  <AIContentSlideModal
    v-model:visible="playlistState.showModals.aiContentSlide"
    :slide-obj="playlistState.activeSlide"
    @update="onSlideUpdate"
    @slide-duration-change="onSlideDurationChange"
    @close="onSlidesUpdateCancel"
  />
  <TemplateConstructorModal
    v-model:visible="playlistState.showModals.smartTemplate"
    :slide-obj="isSmartTemplateActive ? playlistState.activeSlide : null"
    @update="onSlideUpdate"
    @close="onSlidesUpdateCancel"
  />
  <SlideInfoEditModal
    v-model:visible="playlistState.showModals.slideInfo"
    :slide-obj="playlistState.activeSlide"
    @update="onSlideUpdate"
    @close="onSlidesUpdateCancel"
  />
  <SlideObjectFitEditModal
    v-model:visible="playlistState.showModals.editObjectFit"
    :slide-obj="playlistState.activeSlide"
    @update="changeSlideObjectFit"
    @close="onSlidesUpdateCancel"
  />
  <EditModal
    v-model:visible="playlistState.showModals.playlist"
    :playlist="playlist"
    @update="onPlaylistUpdate"
  />
  <SmartPlaylistInfoModal />
  <ExportSlideModal
    v-model:visible="playlistState.showModals.slideExport"
    :playlist-id="playlistId"
    @select="onSlideExportPlaylistsSelect"
    @close="onSlidesUpdateCancel"
  />
  <MoveToOtherGroupModal
    v-if="(!duplicatePlaylistOnMove && currentGroupPlaylistsMovable) || duplicatePlaylistOnMove"
    v-model:visible="playlistState.showModals.playlistMove"
    :exclude-current-group="!duplicatePlaylistOnMove"
    do-not-exclude-branch
    :title="duplicatePlaylistOnMove ? $t('components.playlistPage.duplicatePlaylist') : $t('components.playlistPage.movePlaylist')"
    @select="onPlaylistMoveSelect"
    @close="onPlaylistMoveCancel"
  />
  <SlideModal
    v-model:visible="playlistState.showModals.slide"
    :slide-type="playlistState.activeSlideType"
    :slide="playlistState.activeSlide"
    :is-main-zone="playlistState.activeSlideZoneKey === 'MAIN'"
    @update="onSlideUpdate"
    @save-multiple="onMultipleSlidesSave"
    @slide-duration-change="onSlideDurationChange"
    @close="onSlidesUpdateCancel"
  />

  <ExpirationModal
    v-model:open="playlistState.showModals.playlistExpiration"
    :options="autoDeleteOptions"
    @save="onPlaylistExpirationSettingsChange"
  />
  <a-drawer
    v-model:open="showChangeLog"
    class="custom-class"
    title="Playlist History"
    width="508"
    placement="right"
  >
    <ChangeLogsList />
  </a-drawer>
  <a-layout
    style="height: 100%;"
  >
    <a-layout-content
      id="playlist-page-content"
    >
      <a-spin
        :spinning="loading"
        class="full-height-spinner"
      >
        <template v-if="playlist">
          <a-page-header
            style="position: sticky; z-index: 3; top: 0; background-color: #fff"
          >
            <template #title>
              {{ playlist.name }}
              <a-typography-text
                v-if="autoDeleteOptions"
                type="warning"
              >
                {{ $t('components.playlistPage.playlistExpiresAt', {date: autoDeleteDate}) }}
              </a-typography-text>
            </template>
            <template
              #extra
            >
              <ExtraActions
                :show-playlist-move="!playlistIsMain && currentGroupPlaylistsMovable"
                :show-expiration-settings="!playlistIsMain"
                :show-change-log="playlistVersionsEnabled"
                :show-forced-playlist-option="showForcedPlaylistOption"
                :show-delete-playlist="(!currentGroupTypeIsSmart && !playlistIsMain) || (currentGroupTypeIsSmart && currentGroupPlaylistsMovable)"
                :disable-forced-playlist-option="disableForcedPlaylistOption"
                :hide-rename="playlistIsMain"
                :sider-collapsed="siderCollapsed"
                @edit-playlist="startPlaylistEdit"
                @move-playlist="startPlaylistMove"
                @open-expiration-settings="openExpirationSettings"
                @duplicate-playlist="startPlaylistDuplicate"
                @delete-playlist="$emit('delete-playlist', playlistId)"
                @open-change-log="openChangeLog"
                @override-group-schedule="handleOverrideGroupSchedule"
                @toggle-sider="toggleSider"
              />
            </template>
            <ScheduleLink />
            <div style="display: flex; gap: 8px;">
              <template v-if="!playlistIsMain">
                <PlayPauseButton
                  :is-paused="playlist.paused"
                  :is-scheduled="!!playlist.isScheduled"
                  @toggle="handleTogglePlaylistPause"
                />
              </template>

              <a-tooltip
                placement="top"
                :title="$t('components.playlistPage.shuffleWidgetsTooltip')"
              >
                <a-button
                  :type="!zoningLayoutShuffle ? 'dashed' : 'default'"
                  @click="handleTogglePlaylistShuffle"
                >
                  <template #icon>
                    <NodeIndexOutlined />
                  </template>
                  {{ $t('components.playlistPage.shuffleWidgets') }}: {{ $t(`components.playlistPage.${zoningLayoutShuffle ? 'on': 'off'}`) }}
                </a-button>
              </a-tooltip>
              <SoundtrackButton />
            </div>
          </a-page-header>
          <a-layout-content
            ref="playlistWrapper"
            style="padding: 0 16px 16px; overflow-x: auto"
            class="playlist-wrapper"
            :class="{'dragging':isDraggingNewSlide}"
          >
            <a-collapse
              v-model:activeKey="playlistState.openedLayoutZones"
              ghost
            >
              <ZoneCollapsePanel
                v-for="zone in layoutZonesSettings"
                :ref="(el) => setZoneRefs(zone.key, el)"
                :key="zone.key"
                :total-zones-number="layoutZonesSettings.length"
                :container-height="playlistWrapperHeight"
                :flex="layoutZonesSettings.length === 1"
                :zone-title="$t(`zoneNames.${zone.nameKey}`)"
                :active-zone="zone.zoneKey"
                :zoning-layout-settings="zoningLayoutSettings"
                :zone-key="zone.key"
                :zone-slides="playlistState.zoneSlideListsFiltered[zone.key]"
                :zone-is-empty="zoneIsEmpty(zone.key)"
                :edit-only="editOnlyMode"
                :selected-slide-ids="playlistState.selectedSlideIds"
                :widgets-display-restrictions="widgetDisplayRestrictions?.[zoningLayoutType]?.[zone.key]"
                @zone-slides-move="onSlideListChange"
                @slide-add="onSlideAdd"
                @slide-click="onSlideClick"
                @slide-edit="startSlideInfoEdit"
                @slide-object-fit-edit="startSlideObjectFitEdit"
                @slide-delete="deleteSlide"
                @slide-duration-change="onSlideDurationChange"
                @slide-paused-change="onSlidePausedChange"
                @slide-duplicate="duplicateSlide"
                @slide-export="({slide})=>startSlideExport({slide, zoneKey: zone.key})"
                @slide-mute="toggleSlideSound"
                @slide-start-drag="onStartDragNewSlide"
                @slide-stop-drag="onStopDragNewSlide"
              />
            </a-collapse>
          </a-layout-content>
          <StickyFooter>
            <SelectionFooter
              v-if="playlistState.selectedSlideIds.size"
              :selected-slides-number="playlistState.selectedSlideIds.size"
              @delete="deleteSelectedArrayOfSlides"
              @duplicate="duplicateSelectedOrPassedArrayOfSlides"
              @export="startSlideExport"
              @deselect="deselectAll"
            />
            <FixedFooter>
              <div style="display: flex; gap: 8px;">
                <a-input
                  v-model:value="playlistState.filterSlidesState.input"
                  allow-clear
                  :placeholder="$t('components.playlistPage.filterPlaceholder')"
                  style="width: 100%"
                  @change="debounceFilter"
                />
                <a-segmented
                  v-model:value="playlistState.filterSlidesState.type"
                  :options="filterSlidesOptions"
                />
              </div>
            </FixedFooter>
          </StickyFooter>
        </template>
      </a-spin>
    </a-layout-content>
    <a-layout-sider
      style="background-color: #fff"
      :collapsed="siderCollapsed"
      collapsed-width="0"
      class="playlist-sider"
      width="250px"
    >
      <header>
        <a-segmented
          v-model:value="playlistSiderTab"
          block
          :options="playlistSiderTabOptions"
        />
      </header>
      <a-spin
        :spinning="loading"
      >
        <WidgetsTab
          v-if="playlistSiderTab==='widgets'"
          v-model:filter-slide-types="filterSlideTypes"
          :sider-collapsed="siderCollapsed"
          :slide-type-list="slideTypeList"
          :disabled-widgets="DISABLED_WIDGETS_SLIDE_NAMES"
          :layout-zones-settings="layoutZonesSettings"
          :edit-only-mode="editOnlyMode"
          @start-drag-new-slide="onStartDragNewSlide"
          @stop-drag-new-slide="onStopDragNewSlide"
          @slide-add-last="onSlideAddLast"
        />
        <DesignTab
          v-if="playlistSiderTab === 'design'"
          :transition-type="transitionType"
          :zoning-layout-settings="zoningLayoutSettings"
          @change-layout="handleZoningChange"
          @change-transition="onTransitionChange"
        />
      </a-spin>
    </a-layout-sider>
  </a-layout>
</template>

<style lang="less">
@import '../../styles/variables';

#playlist-page-content {
  .full-height-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  background: #fff;
  height: 100%;
  overflow-x: auto;
  position: relative;
  display: flex;
  flex-direction: column;

  .playlist-empty {
    color: @text-color-secondary;
  }

  .playlist-wrapper {
    display: flex;
    flex-direction: column;
    .ant-collapse {
      flex: 1;
      display: flex;
      flex-direction: column;
      .ant-collapse-item {
        display: flex;
        flex-direction: column;
        .ant-collapse-header {
          position: sticky;
          z-index: 2;
          top: 0;
          background-color: #fff;
        }
        .ant-collapse-content {
          flex: 1;
          flex-direction: column;
          display: flex;
          .ant-collapse-content-box {
            display: flex;
            flex-direction: column;
            flex: 1;
            padding: 0;
          }
        }
      }
      .ant-list {
        border: solid 2px transparent;
        flex: 1;

        .ant-spin-nested-loading {
          height: 100%;

          .ant-spin-container {
            height: 100%;

            .dragArea {
              height: 100%;
            }
          }
        }
      }

      .ant-list {
        min-height: 44px;
      }
    }

    &.dragging {
      .ant-collapse {
        .ant-list {
          border: dashed 2px var(--ant-primary-color);
        }
      }
    }
  }
}

.playlist-sider {
  header {
    padding: 20px 20px 12px 20px;

    .ant-radio-group {
      width: 100%;
      display: flex;

      .ant-radio-button-wrapper {
        flex: 1;
        text-align: center;
      }
    }
  }
  .ant-spin-nested-loading {
    overflow: hidden;
  }
  border-left: solid 1px #f0f0f0;
}
.widget-list-item {
  height: 32px;
  display: flex;
  cursor: pointer;
  .widget-tooltip {
    display: flex;
  }

  &.disabled {
    pointer-events: none;
    opacity: 1;
    cursor: not-allowed;
    background-color: @bg-light-grey;
  }

  &:hover {
    background-color: #f0f0f0;
  }

  &:first-child {
    border-top: solid 1px #f0f0f0;
  }

  .widget-item-icon {
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;

    .add-icon {
      display: none;
    }
  }

  .widget-item-title {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 16px 0 0;

    .anticon {
      display: inline-block;
      width: 24px;
      text-align: left;
    }
  }

  &:hover {
    .widget-item-icon {
      .move-icon {
        display: none;
      }

      .add-icon {
        display: flex;
      }

      &:hover {
        .move-icon {
          display: flex;
          cursor: move;
        }

        .add-icon {
          display: none;
        }
      }
    }
  }
}


</style>
