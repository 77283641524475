<template>
  <a-layout-content style="overflow-x: auto">
    <MoveMediaModal
      v-model:open="showFilesBulkMoveModal"
      @move="handleFilesBulkMove"
      @close="handleCloseMediaMoveModal"
    />

    <MediaTableExpirationModal
      v-model:open="showExpirationModal"
      :options="expirationModalSettings.autoDeleteOptions"
      :media-ids="expirationModalSettings.mediaIds"
      @save="updateMediaExpirationOptions"
    />
    <a-row
      type="flex"
      justify="space-between"
      style="position: sticky; z-index: 10; top: -24px; padding: 24px 16px 16px; margin-top: -24px; background-color: #fff"
    >
      <a-col span="8">
        <a-input-search
          v-model:value="nameFilter"
          :placeholder="$t('components.storageView.filterPlaceholder')"
          style="width: 200px"
        />
      </a-col>
      <a-col>
        <a-space>
          <a-button
            @click="startFolderCreate"
          >
            <template #icon>
              <FolderOutlined />
            </template>
            {{ $t('components.storageView.createFolderButtonText') }}
          </a-button>
          <a-button
            type="primary"
            @click="beforeFileUpload(mediaTypes)"
          >
            <template #icon>
              <UploadOutlined />
            </template>
            {{ $t('components.storageView.uploadButtonText') }}
          </a-button>
        </a-space>
      </a-col>
    </a-row>
    <MediaTable
      ref="mediaTable"
      padded
      :group-id="groupId"
      :exclude-headings="excludeHeadings"
      :types="mediaTypes"
      :selection="selection"
      :select-on-upload="selectOnUpload"
      :show-move="true"
      :folder-select-disabled="folderSelectDisabled"
      :filter="{dataIndex: 'name', value: nameFilter}"
      @set-expiration="onSetExpirationDate"
      @move="onFilesBulkMove"
      @change-folder="onFolderChange"
    />
  </a-layout-content>
  <StickyFooter v-if="selectedMediaIds?.length">
    <FixedFooter>
      <template #left>
        {{ $t('components.storageView.filesSelected', { count: selectedMediaIds.length }, selectedMediaIds.length) }}
      </template>
      <template
        v-if="!disableActions"
        #right
      >
        <a-space>
          <a-button
            size="small"
            @click="onSetExpirationDate()"
          >
            {{ $t('components.storageView.setExpirationDateButtonText') }}
          </a-button>
          <a-button
            size="small"
            @click="onFilesBulkMove()"
          >
            {{ $t('components.storageView.moveButtonText') }}
          </a-button>
          <a-popconfirm
            :title="$t('components.storageView.popConfirmTitleWithCount', null, selectedMediaIds.length)"
            :ok-text="$t('components.storageView.popConfirmOkText')"
            :cancel-text="$t('components.storageView.popConfirmCancelText')"
            placement="bottomRight"
            @confirm="onFilesBulkDelete"
          >
            <a-button size="small">
              {{ $t('components.storageView.deleteButtonText') }}
            </a-button>
          </a-popconfirm>
        </a-space>
      </template>
      <a-button
        size="small"
        @click="deselectAll"
      >
        {{ $t('components.storageView.deselectAllButtonText') }}
      </a-button>
    </FixedFooter>
  </StickyFooter>
</template>

<script>
import { computed, defineComponent, inject, onUnmounted, reactive, ref, toRefs, watch } from 'vue'
import MediaTable from '@/components/tables/MediaTable/MediaTable.vue'
import FixedFooter from '@/components/FixedFooter.vue'
import { FolderOutlined, InboxOutlined, UploadOutlined } from '@ant-design/icons-vue'
import { useStore } from 'vuex'
import { error, success } from '@/utils'
import { MEDIA_TYPES } from '@/constants'
import StickyFooter from '@/components/StickyFooter.vue'
import MediaTableExpirationModal from '@/components/tables/MediaTable/MediaTableExpirationModal.vue'
import MoveMediaModal from '@/components/media/StorageView/MoveMediaModal.vue'

export default defineComponent({
  name: 'StorageView',
  components: {
    MoveMediaModal,
    MediaTableExpirationModal,
    StickyFooter,
    MediaTable,
    FixedFooter,
    UploadOutlined,
    FolderOutlined
  },
  props: {
    groupId: {
      type: String,
      default: () => null
    },
    mediaTypes: {
      type: Array,
      validator (value) {
        return value.every(t =>MEDIA_TYPES.includes(t))
      }
    },
    selection: {
      type: String,
      validator (value) {
        return ['single', 'multiple', 'none'].indexOf(value) !== -1
      }
    },
    selectOnUpload: {
      type: Boolean,
      default: false
    },
    excludeHeadings: {
      type: Array,
      default: () => []
    },
    folderSelectDisabled: Boolean,
    disableActions: Boolean
  },
  setup () {
    const store = useStore()
    const {
      startFileUpload,
      startFolderCreate,
      deleteMediaBulk,
      updateMediaDeleteOptions
    } = inject('mediaService')
    const showFilesBulkMoveModal = ref(false)
    const showExpirationModal = ref(false)
    const expirationModalSettings = reactive({
      mediaIds: null,
      autoDeleteOptions: null
    })
    const mediaTable = ref()
    const nameFilter = ref('')
    const selectedMediaIds = computed(() => store.getters['media/selectedMediaIds'])

    onUnmounted(() => {
      deselectAll()
      store.commit('media/CLEAR_PARENT_DIRECTORY_MEDIA_ID')
    })

    const deselectAll = () => {
      store.commit('media/CLEAR_SELECTED_MEDIA')
    }

    const onFilesBulkMove = () => {
      showFilesBulkMoveModal.value = true
    }

    const moveMedia = (payload) => {
      return store.dispatch('media/moveMediaBulk', payload)
    }

    const handleMoveMedia = async (payload) => {
      return moveMedia(payload).then(() => {
        handleCloseMediaMoveModal()
        deselectAll()
        success()
      })
    }

    const handleDeleteMedia = () => {
      deselectAll()
      success()
    }

    const handleFilesBulkMove = (parentDirectoryMediaId) => {
      const fileIdsToMove = selectedMediaIds.value
      const payload = {
        ids: fileIdsToMove,
        input: {
          parentDirectoryMediaId
        }
      }
      handleMoveMedia(payload).catch((e) => {
        error(e.message || 'Error')
      })
    }

    const onFilesBulkDelete = () => {
      const fileIdsToDelete = selectedMediaIds.value
      deleteMediaBulk(
        { payload: { ids: fileIdsToDelete },
          success: handleDeleteMedia,
          error
        })
    }

    const onSetExpirationDate = ({ mediaId, autoDeleteOptions = {} } = {}) => {
      expirationModalSettings.mediaIds = mediaId ? [mediaId] : selectedMediaIds.value
      expirationModalSettings.autoDeleteOptions = autoDeleteOptions
      showExpirationModal.value = true
    }

    const closeExpirationModal = () => {
      expirationModalSettings.mediaIds = null
      expirationModalSettings.autoDeleteOptions = null
      showExpirationModal.value = false
    }

    const updateMediaExpirationOptions = ({ mediaIds, autoDeleteOptions }) => {
      updateMediaDeleteOptions({ ids: mediaIds, autoDeleteOptions }).then(()=>{
        closeExpirationModal()
        deselectAll()
        success()
      })
      .catch(e => {
        error(e.message)
      })
    }

    const handleCloseMediaMoveModal = () => {
      showFilesBulkMoveModal.value = false
    }

    const onFolderChange = () => {
      nameFilter.value = ''
    }

    const beforeFileUpload = (mediaTypes) => {
      startFileUpload(mediaTypes, ()=>{
        mediaTable.value?.setTableSortByCreateDate && mediaTable.value.setTableSortByCreateDate()
      })
    }

    return {
      mediaTable,
      selectedMediaIds,
      nameFilter,
      showFilesBulkMoveModal,
      showExpirationModal,
      expirationModalSettings,
      startFileUpload,
      startFolderCreate,
      beforeFileUpload,
      deselectAll,
      onFolderChange,
      onFilesBulkMove,
      onFilesBulkDelete,
      onSetExpirationDate,
      handleFilesBulkMove,
      handleCloseMediaMoveModal,
      updateMediaExpirationOptions
    }
  }
})
</script>

<style scoped>

</style>
